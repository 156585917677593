import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import VLibras from "@djpfs/react-vlibras";
import Cabecalho from "./componentes/Cabecalho";
import Inicio from "./componentes/Inicio";
import Footer from "./componentes/Footer";
import PaginaNaoEncontrada from "./componentes/PaginaNaoEncontrada";
import ViewNoticia from "./componentes/ViewNoticia";
import SalaEmpreendedor from "./componentes/SalaEmpreendedor";
import Transparencia from "./componentes/Transparencia";
import Solicitacoes from "./componentes/rh/Solicitacoes";
import Requerimentos from "./componentes/rh/Requerimentos";
import FichaFuncional from "./componentes/rh/FichaFuncional";
import Documentos from "./componentes/rh/Documentos";
import ContraCheque from "./componentes/rh/ContraCheque";
import LeiPauloGustavo from "./componentes/leipaulogustavo";
import NotaFiscal from "./componentes/app/NotaFiscal";
import CodigoTributario from "./componentes/legislacao/CodigoTributario";
import Decretos from "./componentes/legislacao/Decretos";
import DiarioOficial from "./componentes/legislacao/DiarioOficial";
import LeisMunicipais from "./componentes/legislacao/Leismunicipais";
import LeiOrganica from "./componentes/legislacao/Leiorganica";
import Portarias from "./componentes/legislacao/Portarias";
import Editais from "./componentes/arquivos/Editais";
import LDO from "./componentes/arquivos/LDO";
import LOA from "./componentes/arquivos/LOA";
import RGF from "./componentes/arquivos/RGF";
import RREO from "./componentes/arquivos/RREO";
import IPTU from "./componentes/app/IPTU";
import Financas from "./componentes/secretarias/Financas";
import Educacao from "./componentes/secretarias/Educacao";
import Administracao from "./componentes/secretarias/Administracao";
import Cultura from "./componentes/secretarias/Cultura";
import Saude from "./componentes/secretarias/Saude";
import DesenvolvimentoRural from "./componentes/secretarias/DesenvolvimentoRural";
import AssistenciaSocial from "./componentes/secretarias/AssistenciaSocial";
import Prefeito from "./componentes/prefeitura/Prefeito";
import VicePrefeito from "./componentes/prefeitura/VicePrefeito";
import Contratos from "./componentes/arquivos/Contratos";
import Frota from "./componentes/arquivos/Frota";
import TodasNoticias from "./componentes/TodasNoticias";
import NoticiasApp from "./componentes/NoticiasApp";
import Contato from './componentes/app/Contato';
import Licitacoes from './componentes/Licitacoes';
import Chat from './componentes/app/Chat';
import Avisos from './componentes/Avisos';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Rh from './componentes/secretarias/rh';
import Quadro from './componentes/arquivos/Quadro';
import ProcessoSeletivo from './componentes/legislacao/Processoseletivo';
import FarmaciaBasica from './componentes/arquivos/FarmaciaBasica';
import ControleInterno from './componentes/legislacao/ControleInterno';
import MeioAmbiente from './componentes/secretarias/MeioAmbiente';
import AldirBlanc from './componentes/legislacao/aldir_blanc';
import LoadingScreen from 'react-loading-screen'
import { getDatabase, ref, get, onValue } from 'firebase/database';
import firebaseApp, { KEY_REF } from './componentes/adm/admin/firebaseConfig';
import PPA from './componentes/arquivos/PPA';

const database = getDatabase(firebaseApp);

function App() {

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState(null);


  useEffect(() => {
    const dbRef = ref(database, `${KEY_REF}/config/`);
    const unsubscribe = onValue(dbRef, (snapshot) => {
      if (snapshot.exists()) {
        setConfig(snapshot.val());
      } else {
        setConfig(null);
      }
      setLoading(false);
    });
    
  
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingScreen
      loading={true}
      bgColor='white'
      spinnerColor='#9ee5f8'
      textColor='#676767'
      logoSrc='/logo512.png'

    >


    </LoadingScreen>;
  }


  return (

    <>
      <Router>

      <Cabecalho config={config} />

        <VLibras forceOnload={true}></VLibras>

        <Routes>

          <Route path="/" element={<Inicio />} />
          <Route path="/noticias/:titulo/:id" element={<ViewNoticia />} />
          <Route path="/noticias/todas" element={<TodasNoticias />} />
          <Route path="/transparencia" element={<Transparencia />} />
          {/*  <Route path="/saladoempreendedor" element={<SalaEmpreendedor />} /> */}
          <Route path="/leipaulogustavo" element={<LeiPauloGustavo />} />
          <Route path="/avisos" element={<Avisos />} />
          <Route path="/processo_seletivo" element={<ProcessoSeletivo />} />


          <Route path="/rh/cc" element={<ContraCheque />} />

          <Route path="/legislacao/codigotributario" element={<CodigoTributario />} />
          <Route path="/legislacao/decretos" element={<Decretos />} />
          <Route path="/legislacao/aldir_blanc" element={<AldirBlanc />} />
          <Route path="/legislacao/diariosoficial" element={<DiarioOficial />} />
          <Route path="/legislacao/leiorganica" element={<LeiOrganica />} />
          <Route path="/legislacao/leismunicipais" element={<LeisMunicipais />} />
          <Route path="/legislacao/portarias" element={<Portarias />} />
          <Route path="/legislacao/controleinterno" element={<ControleInterno />} />

          <Route path="/licitacoes" element={<Licitacoes />} />

          <Route path="/arquivos/editais" element={<Editais />} />
          <Route path="/arquivos/contratos" element={<Contratos />} />
          <Route path="/arquivos/ldo" element={<LDO />} />
          <Route path="/arquivos/loa" element={<LOA />} />
          <Route path="/arquivos/ppa" element={<PPA />} />
          <Route path="/arquivos/rgf" element={<RGF />} />
          <Route path="/arquivos/rreo" element={<RREO />} />
          <Route path="/arquivos/frota" element={<Frota />} />
          <Route path="/arquivos/quadro" element={<Quadro />} />
          <Route path="/arquivos/farmaciabasica" element={<FarmaciaBasica />} />

          <Route path="/secretarias/financas" element={<Financas />} />
          <Route path="/secretarias/assistenciasocial" element={<AssistenciaSocial />} />
          <Route path="/secretarias/educacao" element={<Educacao />} />
          <Route path="/secretarias/administracao" element={<Administracao />} />
          <Route path="/secretarias/agricultura" element={<Cultura />} />
          <Route path="/secretarias/saude" element={<Saude />} />
          <Route path="/prefeitura/prefeito" element={<Prefeito />} />


          {/*   <Route path="/secretarias/meioambiente" element={<MeioAmbiente />} />
          <Route path="/secretarias/desenvolvimentorural" element={<DesenvolvimentoRural />} /> */}

          {/*        <Route path="/setor/recursoshumanos" element={<Rh />} /> */}


          {/*  <Route path="/prefeitura/viceprefeito" element={<VicePrefeito />} /> */}
          <Route path="*" element={<PaginaNaoEncontrada />} />

        </Routes>


        <Footer />



      </Router>


    </>
  );
}



export default App;